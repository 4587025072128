import React, { useState } from 'react'
import lowerbody1Data from '../DBLocal/lowerbody/lowerbody1_week1'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Accordion from 'react-bootstrap/Accordion'

const Map = () => {
  const [editedData, setEditedData] = useState(lowerbody1Data)

  const handleCellChange = (rowIndex, cellName, newValue) => {
    const updatedData = [...editedData]
    updatedData[rowIndex][cellName] = newValue
    setEditedData(updatedData)
  }

  const handleSetChange = (exerciseIndex, setIndex, newValue) => {
    const updatedData = [...editedData]
    updatedData[exerciseIndex].sets[setIndex].load = newValue
    setEditedData(updatedData)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Save the editedData to the MongoDB database here
  }

  return (
    <div>
      <div style={{ height: '100%' }}>
        <p>Map an Array</p>
        <form onSubmit={handleSubmit}>
          <Accordion>
            {editedData.map((item, index) => (
              <Card key={index}>
                <Accordion.Header eventKey={index.toString()}>
                  {item.name}
                </Accordion.Header>
                <Accordion.Body eventKey={index.toString()}>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>Exercise Name</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={item.name}
                        onChange={(e) =>
                          handleCellChange(index, 'name', e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Details</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={item.details}
                        onChange={(e) =>
                          handleCellChange(index, 'details', e.target.value)
                        }
                      />
                    </Form.Group>
                    {item.sets.map((set, setIndex) => (
                      <Form.Group key={setIndex}>
                        <Form.Label>Set {setIndex + 1} Load</Form.Label>
                        <Form.Control
                          type="text"
                          value={set.load}
                          onChange={(e) =>
                            handleSetChange(index, setIndex, e.target.value)
                          }
                        />
                      </Form.Group>
                    ))}
                    <Form.Group>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        type="text"
                        value={item.notes}
                        onChange={(e) =>
                          handleCellChange(index, 'notes', e.target.value)
                        }
                      />
                    </Form.Group>
                  </Card.Body>
                </Accordion.Body>
              </Card>
            ))}
          </Accordion>
          <div style={{ marginTop: '10px' }}>
            <Button type="submit">Save Changes</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Map
