import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Dropdown from './Dropdown' // Import the new Dropdown component

function MyNavbar() {
  const breneItems = [
    { name: 'Emotions', link: '/emotion-card-list' },
    { name: 'Emotion Wheel', link: '/emotion-wheel' },
  ]

  const polishItems = [
    { name: 'Present & Past tense', link: '/verbs' },
    { name: 'Animals', link: '/animals' },
    { name: 'New Animal', link: '/animals/new' },
    { name: 'Months', link: '/months' },
    { name: 'Numbers', link: '/numbers' },
  ]
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">2024 React</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* Other ways without props in notes */}
          {/* Using the new Dropdown component for Brene */}
          <Dropdown title="Brene" items={breneItems} />

          {/* SSR - Server Side Rendering (MEEN) */}
          <Nav.Link href="https://www.hermcore.com/api">Backend App</Nav.Link>

          {/* //yellow Polish Dropdown -- This doesn't handle nested dropdowns yet */}
          <Dropdown title="Polish" items={polishItems} />

          {/* Polish Dropdown with Grammar and Animals */}
          <NavDropdown title="Polish" id="polish-nav-dropdown">
            {/* Grammar Nested Dropdown */}
            <NavDropdown title="Grammar" id="verbs-sub-dropdown">
              {/* Verbs double Nested Dropdown */}
              <NavDropdown title="Verbs" id="verbs-sub-dropdown">
                <NavDropdown.Item as={Link} to="/verbs">
                  Present & Past tense
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>

            {/* Animals Nested Dropdown */}
            <NavDropdown title="Animals" id="Animal-nav-dropdown">
              <NavDropdown.Item as={Link} to="/animals">
                Animals
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/animals/new">
                New Animal
              </NavDropdown.Item>
            </NavDropdown>

            {/* Polish Sites Nested Dropdown */}
            <NavDropdown title="Polish Sites" id="polish-sites-nav-dropdown">
              <NavDropdown.Item
                href="https://translate.google.com/?sl=pl&tl=en&op=translate"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Translate
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.duolingo.com/lesson"
                target="_blank"
                rel="noopener noreferrer"
              >
                Duolingo
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown.Item as={Link} to="/months">
              Months
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/numbers">
              Numbers
            </NavDropdown.Item>
          </NavDropdown>

          {/* Training Dropdown */}
          <NavDropdown title="Training" id="training-nav-dropdown">
            <NavDropdown.Item
              href="https://hermotafitness.trainerize.com/app/ExerciseLibrary.aspx?mode=all&amp;level=trainer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trainerize
            </NavDropdown.Item>

            <NavDropdown title="Herman" id="hermans-nav-dropdown">
              <NavDropdown.Item as={Link} to="/HermanDashboard">
                Herman's Dashboard
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* Metrics */}
          <NavDropdown title="Metrics" id="metrics-nav-dropdown">
            <NavDropdown.Item as={Link} to="/bodyfat-form">
              Bodyfat Form
            </NavDropdown.Item>
          </NavDropdown>

          {/* PN */}
          <NavDropdown title="PN" id="pn-nav-dropdown">
            {/* Links and resources */}
            <NavDropdown title="PN Links" id="habits-nav-dropdown">
              <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=40">
                PN2 All Lessons
              </NavDropdown.Item>
            </NavDropdown>
            {/* Habits */}
            <NavDropdown title="Habits" id="habits-nav-dropdown">
              <NavDropdown.Item as={Link} to="/pn/habits/findTime">
                1. Find Time
              </NavDropdown.Item>
            </NavDropdown>
            {/* Lessons */}
            <NavDropdown title="Lessons" id="lessons-nav-dropdown">
              <NavDropdown.Item as={Link} to="/pn/lessons/welcomeAccordion">
                1. Welcome
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/oneHabitMethod">
                2. One habit method
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/expectations">
                3. Expectations
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/nutsAndBolts">
                4. NutsAndBolts
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/pn/lessons/gettingMostOutOfStudies"
              >
                5. Getting The Most Out Of Studies
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* PN */}
          <NavDropdown title="PN" id="pn-nav-dropdown">
            {/* Links and resources */}
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=40">
              PN2
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=16&variety=Lesson">
              PN2 Lessons
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=18&variety=Habit">
              PN2 Habits
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=12&variety=Review">
              PN2 Case Studies
            </NavDropdown.Item>

            {/* Habits */}
            <NavDropdown title="Habits" id="habits-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                All Habits
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/habits/findTime">
                1. Find Time
              </NavDropdown.Item>
            </NavDropdown>

            {/* Lessons */}
            <NavDropdown title="Lessons" id="lessons-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                All Lessons
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* react-Bootstrap sass */}
          <NavDropdown title="Bootstrap" id="bootstrap-nav-dropdown">
            <NavDropdown title="Cards" id="cards-nav-dropdown">
              <NavDropdown.Item as={Link} to="/bootstrap/card-sass">
                Card Sass
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tables" id="tables-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                3. N/A
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          <NavDropdown title="Javascript" id="javascript-nav-dropdown">
            <NavDropdown.Item as={Link} to="/map">
              Map
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MyNavbar
