const lowerbody1_week1 = [
  {
    name: "Leg Press",
    date: "September 9 2024",
    details: "Machine",
    settings: "seat height 5",
    sets: [
      { load: "160", reps: 15, rir: 4, rest: "60s" },
      { load: "180", reps: 15, rir: 4, rest: "60s" },
      { load: "200", reps: 15, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Push through your heels.",
  },
  {
    name: "Single Leg, Lying, Eccentric Hamstring Curl",
    details: "Machine",
    sets: [
      { load: "15", reps: 15, rir: 4, rest: "60s" },
      { load: "15", reps: 20, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Engage your glutes throughout the movement. Add tempo to exercise.",
  },
  {
    name: "Glute Drive",
    details: "Machine",
    sets: [
      { load: "90", reps: 15, rir: 4, rest: "60s" },
      { load: "90", reps: 15, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Hold at the top for 2 seconds.",
  },
  {
    name: "Standing Side Cable Abduction",
    details: "Cable Machine",
    sets: [
      { load: 10, reps: 15, rir: 4, rest: "60s" },
      { load: 10, reps: 15, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your upper body steady.",
  },
  {
    name: "Seated Adductor Machine",
    details: "Machine",
    sets: [
      { load: "140", reps: 15, rir: 4, rest: "60s" },
      { load: "155", reps: 15, rir: 4, rest: "60s" },
      { load: "155", reps: 15, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Control the movement both ways. Add stimulus algorithm.",
  },
];

export default lowerbody1_week1;
