import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MyNavbar from './Navbar/MyNavbar'
import Noun from './Polish/Noun'
import Psrp from './Polish/pspr'
import EmotionCardList from './Mindset/Brene/EmotionCardList'
import BodyFatForm from './Metrics/BodyFatForm'
import BodyFatResult from './Metrics/BodyFatResult'
import MonthsDeclensions from './Polish/MonthsDeclensions'
import NumbersDeclensions from './Polish/NumbersDeclensions'
import AnimalsReadAll from './Animals/AnimalsReadAll'
import AnimalCreateForm from './Animals/AnimalCreateForm'
import HermanDashboard from './Herman/HermanDashboard'
import EmotionWheel from './Mindset/EmotionWheel'
import WorkoutView from './Herman/WorkoutView' // New workout view component
import CardSass from './Bootstrap5Components/CardSass/CardSass'
import FindTime from './PN/Habits/FindTime'
import WelcomeAccordion from './PN/Lessons/WelcomeAccordion'
import OneHabitMethod from './PN/Lessons/OneHabitMethod'
import Expectations from './PN/Lessons/Expectations'
import NutsAndBolts from './PN/Lessons/NutsAndBolts'
import GettingMostOutOfStudies from './PN/Lessons/GettingMostOutOfStudies'
import Map from './Javascript/Map'

function App() {
  return (
    <Router>
      <div className="App">
        <MyNavbar />
        <Routes>
          <Route path="/" element={<HermanDashboard />} />
          <Route path="/verbs" element={<Psrp />} />
          <Route path="/noun" element={<Noun />} />
          <Route path="/months" element={<MonthsDeclensions />} />
          <Route path="/numbers" element={<NumbersDeclensions />} />
          <Route path="/emotion-card-list" element={<EmotionCardList />} />
          <Route path="/emotion-wheel" element={<EmotionWheel />} />
          <Route path="/bodyfat-form" element={<BodyFatForm />} />
          <Route path="/bodyfat-result" element={<BodyFatResult />} />
          <Route path="/animals" element={<AnimalsReadAll />} />
          <Route path="/animals/new" element={<AnimalCreateForm />} />
          <Route path="/HermanDashboard" element={<HermanDashboard />} />
          <Route path="/pn/habits/findTime" element={<FindTime />} />
          <Route path="/map" element={<Map />} />
          <Route
            path="/pn/lessons/welcomeAccordion"
            element={<WelcomeAccordion />}
          />
          <Route
            path="/pn/lessons/oneHabitMethod"
            element={<OneHabitMethod />}
          />
          <Route path="/pn/lessons/expectations" element={<Expectations />} />
          <Route path="/pn/lessons/nutsAndBolts" element={<NutsAndBolts />} />
          <Route
            path="/pn/lessons/gettingMostOutOfStudies"
            element={<GettingMostOutOfStudies />}
          />
          <Route path="/bootstrap/card-sass" element={<CardSass />} />

          {/* New dynamic route for workouts  */}
          <Route path="/workout/:workoutType" element={<WorkoutView />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
